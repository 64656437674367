// 登录、注册
const PAGE_AUTH = '/pages/auth/auth.html'


// 首页
const PAGE_HOME = '/pages/home/home.html'

// 专栏
const PAGE_COLUMN = '/pages/column/column.html'

// 学科
const PAGE_SUBJECT = '/pages/subject/subject.html'

// 分类
const PAGE_CATEGORY = '/pages/category/category.html'

// 丛书
const PAGE_SERIESES = '/pages/serieses/serieses.html'
// 丛书内容 ?id=
// id => 丛书id
const PAGE_SERIES = '/pages/serieses/series/series.html'


// 搜索 ?searchKey=
// searchKey => 搜索关键字
const PAGE_SEARCH = '/pages/search/search.html'

// 通知
const PAGE_NOTICES = '/pages/notices/notices.html'


// 图书 ?id=
// id => 图书id
const PAGE_BOOK = '/pages/book/book.html'
// 图书 - 目录 ?bid=&cid=&tid=&sid=&action=
// bid => 图书id
// cid => 章节id
// tid => 资源类型(仅cid存在时有效)
// sid => 资源id(仅tid存在时有效)
// action => 资源动作(仅sid存在时有效)
const PAGE_BOOK_CATALOG = '/pages/book/catalog/catalog.html'
// 图书 - 目录 - 练习题 ?id=&bid=&cid=&answer=&version=
// id => 习题id
// bid => 图书id
// cid => 章节id
// answer => 是否为答题模式
// version => 答案版本号(仅answer为false时有效)
const PAGE_BOOK_CATALOG_EXERCISE = '/pages/book/catalog/exercise/exercise.html'

// 图书 - 目录 - 图文内容
const PAGE_BOOK_CATALOG_RICH_TEXT = '/pages/book/catalog/rich-text/rich-text.html'

// 图书 - 目录 - 练习题 - 下载 ?id=
// id => 习题id
const PAGE_BOOK_CATALOG_EXERCISE_DOWNLOAD = '/pages/book/catalog/exercise/download/download.html'
// 图书 - 教师申请 ?id=
// id => 图书id
const PAGE_BOOK_TEACHER_APPLY = '/pages/book/teacher-apply/teacher-apply.html'
// 图书 - 申请样书 ?id=
// id => 图书id
const PAGE_BOOK_SAMPLE_APPLY = '/pages/book/sample-apply/sample-apply.html'
// 图书 - 购买课程
const PAGE_BOOK_PURCHASE = '/pages/book/purchase/purchase.html'
// 图书 - 购买课程 - 订单支付 ?id=
// id => 订单id
const PAGE_BOOK_PURCHASE_PAYMENT = '/pages/book/purchase/payment/payment.html'


// 个人中心 - 个人信息
const PAGE_MINE_PROFILE = '/pages/mine/profile/profile.html'
// 个人中心 - 我的订单
const PAGE_MINE_ORDERS = '/pages/mine/orders/orders.html'
// 个人中心 - 我的练习
const PAGE_MINE_EXERCISES = '/pages/mine/exercises/exercises.html'
// 个人中心 - 我的优惠
const PAGE_MINE_COUPONS = '/pages/mine/coupons/coupons.html'
// 个人中心 - 领券中心
const PAGE_MINE_COUPONS_CENTER = '/pages/mine/coupons/center/center.html'
// 个人中心 - 我的收藏
const PAGE_MINE_COLLECTIONS = '/pages/mine/collections/collections.html'
// 个人中心 - 学习报告
const PAGE_MINE_STUDY_REPORTS = '/pages/mine/study-reports/study-reports.html'
// 个人中心 - 学习报告 - 观看记录 ?bid=
// bid => 图书id
const PAGE_MINE_STUDY_REPORT = '/pages/mine/study-reports/report/report.html'
// 个人中心 - 开票历史
const PAGE_MINE_INVOICES = '/pages/mine/invoices/invoices.html'
// 个人中心 - 地址管理
const PAGE_MINE_ADDRESSES = '/pages/mine/addresses/addresses.html'
// 个人中心 - 购物车
const PAGE_MINE_CART = '/pages/mine/cart/cart.html'
// 个人中心 - 申请样书
const PAGE_MINE_SAMPLES = '/pages/mine/samples/samples.html'
// 个人中心 - 我的投稿
const PAGE_MINE_CONTRIBUTIONS = '/pages/mine/contributions/contributions.html'
// 个人中心 - 投稿 ?id=
// id => 投稿id
const PAGE_MINE_CONTRIBUTE = '/pages/mine/contributions/contribute/contribute.html'


const routers = [
  {
    href: PAGE_AUTH,
    name: '登录',
    level: 1
  },
  {
    href: PAGE_HOME,
    name: '首页',
    level: 1
  },
  {
    href: PAGE_COLUMN,
    name: '专栏',
    level: 1
  },
  {
    href: PAGE_SUBJECT,
    name: '学科',
    level: 1
  },
  {
    href: PAGE_CATEGORY,
    name: '分类',
    level: 1
  },
  {
    href: PAGE_SERIESES,
    name: '丛书',
    level: 1
  },
  {
    href: PAGE_SERIES,
    name: '丛书内容',
    level: 1
  },
  {
    href: PAGE_SEARCH,
    name: '搜索',
    level: 1
  },
  {
    href: PAGE_NOTICES,
    name: '通知',
    level: 1
  },
  {
    href: PAGE_BOOK,
    name: '课程详情'
  },
  {
    href: PAGE_BOOK_CATALOG,
    name: '章节'
  },
  {
    href: PAGE_BOOK_CATALOG_EXERCISE,
    name: '练习题'
  },
  {
    href: PAGE_BOOK_TEACHER_APPLY,
    name: '教师申请'
  },
  {
    href: PAGE_BOOK_SAMPLE_APPLY,
    name: '申请样书'
  },
  {
    href: PAGE_BOOK_PURCHASE,
    name: '购买课程'
  },
  {
    href: PAGE_BOOK_PURCHASE_PAYMENT,
    name: '订单支付'
  },
  {
    href: PAGE_MINE_PROFILE,
    name: '个人信息',
    level: 1
  },
  {
    href: PAGE_MINE_ORDERS,
    name: '我的订单',
    level: 1
  },
  {
    href: PAGE_MINE_EXERCISES,
    name: '我的练习',
    level: 1
  },
  {
    href: PAGE_MINE_COUPONS,
    name: '我的优惠',
    level: 1
  },
  {
    href: PAGE_MINE_COUPONS_CENTER,
    name: '领券中心'
  },
  {
    href: PAGE_MINE_COLLECTIONS,
    name: '我的收藏',
    level: 1
  },
  {
    href: PAGE_MINE_STUDY_REPORTS,
    name: '学习报告',
    level: 1
  },
  {
    href: PAGE_MINE_STUDY_REPORT,
    name: '观看记录'
  },
  {
    href: PAGE_MINE_INVOICES,
    name: '开票历史',
    level: 1
  },
  {
    href: PAGE_MINE_ADDRESSES,
    name: '地址管理',
    level: 1
  },
  {
    href: PAGE_MINE_CART,
    name: '购物车',
    level: 1
  },
  {
    href: PAGE_MINE_SAMPLES,
    name: '申请样书',
    level: 1
  },
  {
    href: PAGE_MINE_CONTRIBUTIONS,
    name: '我的投稿',
    level: 1
  },
  {
    href: PAGE_MINE_CONTRIBUTE,
    name: '投稿'
  }
]

function parseAppRoutes() {
  const tabs = routers.filter(item => item.level === 1)
  const getRoutes = getStorage(STORAGE_ROUTERS)

  let routes = getRoutes && getRoutes.length ? getRoutes : []
  const location = window.location.href
  console.log(location, '=====location====================>')
  let flag = true
  for (let i = 0; i <= tabs.length; i++) {
    const index = location.indexOf(tabs[i]?.href)
    if (index >= 0) {
      flag = false
      routes = [{
        name: tabs[i].name,
        href: location,
        path: tabs[i].href
      }]
    }
  }
  if (flag) {
    const route = routers.filter(item => location.indexOf(item.href) >= 0)[0]
    console.log(route,routers, '==========route=================>')
    const checkIndex = routes.findIndex(item => location.indexOf(item.path) >= 0)
    if (checkIndex >= 0) {
      routes.splice(checkIndex, routes.length - 1, {
        name: route.name,
        href: location,
        path: route.href
      })
    } else {
      if (route && route.name) {
        routes.push({
          name: route.name,
          href: location,
          path: route.href
        })
      }
    }
  }
  putStorage(STORAGE_ROUTERS, routes)
}

$(() => {
  parseAppRoutes()
})
